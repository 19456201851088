import React from "react"
import Layout from "../components/layout"
import styled from "@emotion/styled"
import { Global, css } from "@emotion/react"
import {color, spacing, screen} from "../constants"

import { Form, Field } from 'react-final-form'

import useToggle from "../components/useToggle"
import Footer from "../components/footer"

export default function MitgliedWerden() {
  const [formIsSent, setFormIsSent] = React.useState(false);

  const onSubmit = async values => {
    setFormIsSent(0);
    // window.alert(JSON.stringify(values, 0, 2))
    const recipeUrl = '/z9sudfgidfgfiuzgb8z.php';
    const postBody = JSON.stringify(values,0,2);
    const requestMetadata = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: postBody
    };

    fetch(recipeUrl, requestMetadata)
        .then(res => res.text())
        .then(data => {
            // console.log(data);
            if(data == 200) {
              setFormIsSent(200)
            } else {
              console.log(data);
              setFormIsSent(500);
            }
        }).catch(
          error => {
            console.log(error);
            setFormIsSent(500);
        });

  }

  const AboForm = () => (
    <Form
      onSubmit={onSubmit}
      initialValues={{}}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit}
          css={css`
            & > div {
              margin-top: 1em;
              display: flex;
              & > label {
                display: flex;
                align-items: center;
              }
              & input[type='checkbox'] {
                width: 20px;
                height: 20px;
                margin-right: 1em;
                flex-shrink: 0;
              }
              & input[type='text'],input[type='email'] {
                font-size: 1em;
                margin-left: 1em;
              }
              & button {
                font-size: 1em;
                padding: 0.3em;
              }
            }
          `}>
          <div>
            <label>
              <Field
                name="auswahl"
                component="input"
                type="checkbox"
                value="Ich möchte für CHF 50.-/Jahr Einzelmitglied werden"
              />{' '}
            Ich möchte für CHF 50.-/Jahr Einzelmitglied werden
            </label>
          </div>
          <div>
            <label>
              <Field
                name="auswahl"
                component="input"
                type="checkbox"
                value="Wir möchten für CHF 80.-/Jahr Paarmitglieder werden"
              />{' '}
            Wir möchten für CHF 80.-/Jahr Paarmitglieder werden
            </label>
          </div>
          <div>
            <label>
              <Field
                name="auswahl"
                component="input"
                type="checkbox"
                value="Ich möchte ein Saisonabonnement erwerben"
              />{' '}
            Ich möchte ein Saisonabonnement erwerben
            </label>
          </div>
          <div>
            <label>
              <Field
                name="auswahl"
                component="input"
                type="checkbox"
                value="Ich bin interessiert an einem Sponsoring, bitte nehmen Sie Kontakt mit mir auf."
              />{' '}
            Ich bin interessiert an einem Sponsoring, bitte nehmen Sie Kontakt mit mir auf.
            </label>
          </div>
          <div>
            <label>
            oder ich unterstütze die GML mit einer Spende von: <Field
                name="spendebetrag"
                component="input"
                type="number"
                css={css`
                  width: 4em;
                  height: 1.8em;
                  margin-left: 0.5em;
                  margin-right: 0.25em;
                  font-size: 1em;
                  text-align: right;
                `}
              />{' '} CHF
            </label>
          </div>
          <div style={{marginTop: '2em'}}>
            <label style={{width: '55px'}}>Name</label>
            <Field
              name="name"
              component="input"
              type="text"
              placeholder="Name/Vorname"
            />
          </div>
          <div>
            <label style={{width: '55px'}}>E-Mail</label>
            <Field
              name="email"
              component="input"
              type="email"
              placeholder="ihre@email.ch"
            />
          </div>
          <div>
            <label style={{width: '55px'}}>Strasse</label>
            <Field
              name="strasse"
              component="input"
              type="text"
              placeholder="Strasse"
            />
          </div>
          <div>
            <label style={{width: '55px'}}>PLZ/Ort</label>
            <Field
              name="ort"
              component="input"
              type="text"
              placeholder="PLZ/Ort"
            />
          </div>
          <div style={{marginTop: '2em'}}>
            <label>
              <Field
                name="kommunikation"
                component="input"
                type="checkbox"
                value="Bitte per Papierpost"
              />{' '}
            Unsere Kommunikation wird in der Regel per E-Mail abgewickelt. Falls Sie lieber Papierpost von uns möchten. kreuzen Sie bitte hier an.
            </label>
          </div>
          <div className="buttons">
            <button type="submit" disabled={submitting || pristine || values.name == null || values.email == null || values.strasse == null || values.ort == null}>
              Absenden
            </button>
          </div>
          <pre style={{display: 'none'}}>{JSON.stringify(values, 0, 2)}</pre>
        </form>
      )}
    />
    )



  return (
    <Layout title="Mitglied werden" description="Werden Sie Mitglied! Saison für Saison spricht die GML Kreuzlingen mit einem vielseitigen Programm viele Menschen an. Als Mitglied leisten Sie einen Beitrag dazu, dass dies auch in Zukunft möglich ist." >
      <main css={css`
        background-color: ${color.light};
        flex: 1 1 980px;
        
        padding: ${spacing.xs};
        margin: 0;

        @media (min-width: ${screen.xsPhone}) {
          padding: ${spacing.medium};
          margin: 0 ${spacing.small};
        }
        @media (min-width: ${screen.tablet}) {
          padding: calc(0.75*${spacing.big}) ${spacing.big};
          margin: 0 ${spacing.medium};
        }
      `}>
        <h1>Für unsere Mitglieder und Abonnent*innen</h1>
        <h5 style={{fontSize: '1.15em', margin: '2em 0 1em 0'}}>Werden Sie Mitglied!</h5>
        <p>
          Saison für Saison spricht die GML Kreuzlingen mit einem vielseitigen Programm viele Menschen an. Als Mitglied leisten Sie einen Beitrag dazu, dass dies auch in Zukunft möglich ist. Zudem profitieren Sie persönlich von einigen konkreten Vorteilen:
          <ul css={css`
            margin-bottom: 2em;
            & > li > span {
              font-weight: bold;
              color: ${color.secondaryDark};
            }
          `}>
            <li>Wir laden Sie zu allen Konzerten <span>persönlich</span> ein</li>
            <li>Auf Wunsch <span>reservieren</span> wir Ihre Plätze</li>
            <li>Sie nehmen an der Generalversammlung teil und können dort <span>mitreden und mitbestimmen</span></li>
            <li>Beim Pausenapéro erhalten Sie eine <span>Reduktion</span> auf Getränke</li>
            <li>Einmal pro Konzertsaison laden wir Mitglieder, Abonnent*innen, Sponsoren und Gönner zum <span>exklusiven Gratiskonzert</span> und <span>Apéro</span> ein</li>
          </ul>
          <h5 style={{fontSize: '1.15em', margin: '2em 0 1em 0'}}>Werden Sie Abonnent*in!</h5>
          <ul css={css`
            margin-bottom: 2em;
            & > li > span {
              font-weight: bold;
              color: ${color.secondaryDark};
            }
          `}>
            <li>Sie nehmen an allen Abonnementskonzerten auf Ihrem bevorzugten Sitzplatz teil.</li>
            <li>Zusätzlich überreichen wir Ihnen bei Ihrem ersten Konzertbesuch einen Gratiseintritt</li>
            <li>Sie erhalten 20% Vergünstigung pro Konzert</li>
          </ul>
        </p>

        <div css={css`
          background-color: rgba(255,234,224,1); //  #ffd8c6; //rgb(255, 216, 198)
          padding: 2em;
          @media (max-width: ${screen.xsPhone}) {
            padding: 0.5em;
          }
        `}>
          {
            formIsSent == 0 
            ?
            <>
            <h5 style={{fontSize: '1.25em', margin: '0'}}>Wie können Sie die GML unterstützen:</h5>
            <p>Mit Ihrer Mitgliedschaft, Ihrem Saisonabonnement, Ihrer Spende oder Ihrem Sponsoring</p>
            <AboForm />
            </>
            : 
            (
              formIsSent == 200 
              ?
              <h5 style={{fontSize: '1.25em', margin: '0'}}>Vielen Dank für Ihre Unterstützung!</h5>
              : 
              <h5 style={{fontSize: '1.25em', margin: '0'}}>Es ist ein Fehler aufgetreten. Bitte melden Sie sich per Mail bei uns: <br/>info@gml-kreuzlingen.ch</h5>
            )
            
          }
          
        </div>
        
        <Footer marginTop={true} />
      </main>
    </Layout>
  )
}
